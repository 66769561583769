import {ApolloProvider} from "@apollo/client";
import MomentUtils from "@date-io/moment";
import {CssBaseline} from "@material-ui/core";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import {ConfirmProvider} from "material-ui-confirm";
import {SnackbarProvider} from "notistack";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../css/react-dates-overrides.css";
import "@fullcalendar/common/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/list/main.css";
import {ThemeContextProvider} from "@/styles/theme";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import "../css/react-dates-overrides.css";
import "@fullcalendar/common/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/list/main.css";
import {useEffect} from "react";
import {useApollo} from "@/lib/apolloClient/frontend";
import {ProfileProvider} from "@/components/Auth/ProfileProvider";
import {ChecklistProvider} from "@/contexts/ChecklistContext";
function MyApp({Component, pageProps: {session, ...pageProps}}: { Component: any, pageProps: any }) {
  const apolloClient = useApollo(
    typeof pageProps.initialApolloState === "string"
      ? JSON.parse(pageProps.initialApolloState)
      : pageProps.initialApolloState
  );
  let trackingSetupInterval = 0;
  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.parentElement?.removeChild(jssStyles);
    }
  }, []);

  return (
    <ApolloProvider client={apolloClient}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ThemeContextProvider>
          <ProfileProvider>
            <ChecklistProvider>
              <SnackbarProvider maxSnack={3}>
                {/* @ts-ignore */}
                <ConfirmProvider>
                <CssBaseline/>
                <Component {...pageProps} />
              </ConfirmProvider>
            </SnackbarProvider>
            </ChecklistProvider>
          </ProfileProvider>
        </ThemeContextProvider>
      </MuiPickersUtilsProvider>
    </ApolloProvider>
  );
}

export default MyApp;
