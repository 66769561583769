import {UserProfileFragment, useMyProfileQuery} from "../generated/graphql";
import {parseCookies, setCookie, destroyCookie} from "nookies";
import {useCallback, useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {useApollo} from "../lib/apolloClient/frontend";
import {getRolePermissions, getUserPermissions, hasPermission} from "../config/permissions";

export function applicationLinkForContact({companyName, email}: { companyName: string, email: string }) {
  return `https://yrzrxhm0qtt.typeform.com/to/pdc7DMta#company=${encodeURIComponent(companyName)}&contact=${encodeURIComponent(email)}`
}

export function useAuth() {
  // This should make frontend auth respect the impersonate cookie
  // since we never actually use the session.user and always
  // perform a `me` lookup
  const user = useMyProfileQuery()
  const {impersonate, impersonator} = parseCookies()
  const isImpersonated = (user.data?.myProfile?.email === impersonate)
  const userPermissions = getUserPermissions(user.data?.myProfile as UserProfileFragment)
  const rolePermissions = getRolePermissions(user.data?.myProfile as UserProfileFragment)
  const _hasPermission = useCallback((p: string) => hasPermission(user.data?.myProfile as UserProfileFragment, p), [user.data?.myProfile])

  const hasCultivation = user?.data?.myProfile?.organization?.moduleEnabledCultivation
  const hasProcessing = user?.data?.myProfile?.organization?.moduleEnabledProcessing
  const hasFinance = user?.data?.myProfile?.organization?.moduleEnabledFinance

  return {
    loading:user.loading,
    currentUser: user.data?.myProfile,
    isImpersonated,
    hasPermission: _hasPermission,
    userPermissions,
    rolePermissions,
    impersonator,
    hasCultivation,
    hasProcessing,
    hasFinance,
    refetch: user.refetch
  }
}

export function useTracking() {
  const [tracked, setTracked] = useState(false)
  const {isImpersonated} = useAuth()
  return useCallback((name: string) => {
    const interval = setInterval(() => {
      if (tracked && interval)
        clearInterval(interval)
      if (window.ChurnZero?.ready && !tracked && process.env.NODE_ENV === "production" && !isImpersonated) {
        console.info("ChurnZero Track Event:", name)
        window.ChurnZero?.push(['trackEvent', name])
        setTracked(true)
        clearInterval(interval)
      }
    }, 2000)
    return () => clearInterval(interval)
  }, [tracked])
}

export function useTrackPageLoad(pageName: string) {
  const TrackEvent = useTracking()
  useEffect(() => {
    TrackEvent(`${pageName}.PageLoad`)
  }, [])
}

export function useImpersonateUser() {
  const client = useApollo({});
  const cookies = parseCookies()
  const { currentUser } = useAuth();
  const [impersonating, setImpersonating] = useState(cookies.__impersonate || "")
  const {enqueueSnackbar} = useSnackbar()

  const endImpersonate = useCallback(async () => {
    destroyCookie(null, "__impersonate", {path: "/"})
    setImpersonating("")
    enqueueSnackbar(`Ended impersonation`, {variant: "success"})
    enqueueSnackbar(`Please be patient, switching users takes a moment...`)
    window.location.pathname = '/app'
    await client.resetStore()
  }, [])

  const beginImpersonate = useCallback(async (userId: string, days = 1) => {
    if (!userId) {
      return await endImpersonate();
    }

    setCookie(null, '__impersonate', userId, {
      maxAge: 24 * 60 * 60 * days,
      path: '/'
    });
    
    setImpersonating(userId);
    enqueueSnackbar(`Beginning to impersonate ${userId}`, {variant: "success"});
    enqueueSnackbar(`Please be patient, switching users takes a moment...`);
    window.location.pathname = '/app';
    await client.resetStore();
  }, [currentUser, endImpersonate]);

  

  return {impersonating, endImpersonate, beginImpersonate}
}
