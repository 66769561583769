import React, { createContext, useContext, useState, useEffect } from 'react';
import { useMyProfileQuery, OrganizationFieldsFragment, ChecklistTaskStatus } from '@/generated/graphql';
import { useRouter } from 'next/router';
import { useAuth } from '@/hooks/auth';
import { CREATE_USER_PERMISSION } from '@/config/permissions';

interface ChecklistContextType {
  isChecklistModalOpen: boolean;
  openChecklistModal: () => void;
  closeChecklistModal: () => void;
  currentOrganization: OrganizationFieldsFragment | null | undefined;
  isChecklistComplete: boolean;
  getTaskStatus: (taskName: string) => ChecklistTaskStatus | null;
  refetchChecklist: () => Promise<any>;
  incompleteTasksCount: number;
}

const ChecklistContext = createContext<ChecklistContextType>({
  isChecklistModalOpen: false,
  openChecklistModal: () => {},
  closeChecklistModal: () => {},
  currentOrganization: null,
  isChecklistComplete: false,
  getTaskStatus: () => null,
  refetchChecklist: async () => {},
  incompleteTasksCount: 0,
});

export const useChecklist = () => useContext(ChecklistContext);

export const ChecklistProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isChecklistModalOpen, setIsChecklistModalOpen] = useState(false);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  const {hasPermission, currentUser, loading, refetch} = useAuth();
  const isAdmin = hasPermission(CREATE_USER_PERMISSION);
  const currentOrganization = currentUser?.organization;

  const router = useRouter();

  // Determine if checklist is complete
  const isChecklistComplete = React.useMemo(() => {
    if (!currentOrganization?.checklist?.checklistTasks?.length) return false;
    
    // Check if all tasks are either CUSTOM_DATA_CREATED or COMPLETE
    return currentOrganization.checklist.checklistTasks.every(
      task => task?.status === ChecklistTaskStatus.CustomDataCreated || task?.status === ChecklistTaskStatus.Complete
    );
  }, [currentOrganization]);
  
  // Count incomplete tasks
  const incompleteTasksCount = React.useMemo(() => {
    if (!currentOrganization?.checklist?.checklistTasks?.length) return 0;
    
    return currentOrganization.checklist.checklistTasks.filter(
      task => task?.status !== ChecklistTaskStatus.CustomDataCreated && task?.status !== ChecklistTaskStatus.Complete
    ).length;
  }, [currentOrganization]);
  
  // Function to get the status of a specific task
  const getTaskStatus = (taskName: string): ChecklistTaskStatus | null => {
    if (!currentOrganization?.checklist?.checklistTasks?.length) return null;
    
    const task = currentOrganization.checklist.checklistTasks.find(
      task => task?.name === taskName
    );
    
    return task?.status || null;
  };
  
  // Function to refetch the checklist data
  const refetchChecklist = async () => {
    return await refetch();
  };
  
  // Auto-open checklist modal on first load if checklist is not complete
  useEffect(() => {
    // Only proceed if we have data and loading is complete
    if (!loading && currentOrganization && !initialLoadComplete && isAdmin) {
      setInitialLoadComplete(true);
      
      // Check if user has a checklist and it's not complete
      const hasIncompleteChecklist = 
        currentOrganization.checklist && 
        !isChecklistComplete;
      
      // Check if user has dismissed the checklist before
      const isDismissed = localStorage.getItem('checklistDismissed') === 'true';

      // Check if user is on the dashboard page
      console.log(router.pathname);
      const isDashboardPage = router.pathname === '/app/cultivation/dashboard';
      
      // Open the modal if checklist is incomplete and not dismissed
      if (hasIncompleteChecklist && !isDismissed && isDashboardPage) {
        setIsChecklistModalOpen(true);
      }
    }
  }, [currentOrganization, isChecklistComplete, loading, initialLoadComplete, router.pathname]);
  
  const openChecklistModal = () => {
    // Refetch data before opening to ensure it's up to date
    refetch().then(() => {
      setIsChecklistModalOpen(true);
    });
  };
  
  const closeChecklistModal = () => {
    setIsChecklistModalOpen(false);
    // Remember that user dismissed the checklist
    localStorage.setItem('checklistDismissed', 'true');
  };
  
  return (
    <ChecklistContext.Provider 
      value={{ 
        isChecklistModalOpen, 
        openChecklistModal, 
        closeChecklistModal,
        currentOrganization,
        isChecklistComplete,
        getTaskStatus,
        refetchChecklist,
        incompleteTasksCount
      }}
    >
      {children}
    </ChecklistContext.Provider>
  );
}; 