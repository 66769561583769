import React, { FC, useContext, useState, createContext } from "react";
import {
  createTheme,
  responsiveFontSizes,
  ThemeProvider,
} from "@material-ui/core/styles";
import { useRouter } from "next/router";
import { FINANCE as FinanceRoute, PROCESSING as ProcessingRoute } from "../config/routes";
import { Theme } from "@material-ui/core";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const SUCCESS_GREEN = {
  main: "#589853",
};
const WARNING_ORANGE = {
  main: "#EDA340",
};
const ERROR_RED = {
  main: "#E8605A",
};

const INFO_BLUE = {
  main: "#007bff",
};

const NON_MODULE_COLORS = {
  success: SUCCESS_GREEN,
  warning: WARNING_ORANGE,
  error: ERROR_RED,
  info: INFO_BLUE,
};

const CULTIVATION = {
  dark: "#1a1a1a", // Very dark gray (almost black) for dark mode
  light: "#f5f5f5", // Very light gray (almost white) for light mode
  contrastText: "#333333", // Dark gray text for light mode, light gray text for dark mode
};
const PROCESSING = {
  dark: "#476bb7", // Processing Blue
  light: "#f0f4fa", // Very light blue-tinted gray for light mode
  contrastText: "#333333", // Dark gray text for light mode, light gray text for dark mode
};
const FINANCIALS = {
  dark: "#969cac", // Finance Grey
  light: "#f7f8fa", // Very light gray with a hint of blue for light mode
  contrastText: "#333333", // Dark gray text for light mode, light gray text for dark mode
};

// Update themes
export const lightModeCultivation = responsiveFontSizes(
  createTheme({
    palette: {
      type: "light",
      primary: { main: CULTIVATION.dark, contrastText: "#f0f0f0" },
      secondary: { main: CULTIVATION.light, contrastText: CULTIVATION.contrastText },
      ...NON_MODULE_COLORS,
    },
  })
);

export const darkModeCultivation = responsiveFontSizes(
  createTheme({
    palette: {
      type: "dark",
      primary: { main: CULTIVATION.light, contrastText: CULTIVATION.contrastText },
      secondary: { main: CULTIVATION.dark, contrastText: "#f0f0f0" },
      ...NON_MODULE_COLORS,
    },
  })
);

export const lightModeProcessing = responsiveFontSizes(
  createTheme({
    palette: {
      type: "light",
      primary: { main: PROCESSING.dark, contrastText: "#f0f0f0" },
      secondary: { main: PROCESSING.light, contrastText: PROCESSING.contrastText },
      ...NON_MODULE_COLORS,
    },
  })
);

export const darkModeProcessing = responsiveFontSizes(
  createTheme({
    palette: {
      type: "dark",
      primary: { main: PROCESSING.dark, contrastText: "#f0f0f0" },
      secondary: { main: PROCESSING.light, contrastText: PROCESSING.contrastText },
      ...NON_MODULE_COLORS,
    },
  })
);

export const lightModeFinance = responsiveFontSizes(
  createTheme({
    palette: {
      type: "light",
      primary: { main: FINANCIALS.dark, contrastText: "#f0f0f0" },
      secondary: { main: FINANCIALS.light, contrastText: FINANCIALS.contrastText },
      ...NON_MODULE_COLORS,
    },
  })
);

export const darkModeFinance = responsiveFontSizes(
  createTheme({
    palette: {
      type: "dark",
      primary: { main: FINANCIALS.dark, contrastText: "#f0f0f0" },
      secondary: { main: FINANCIALS.light, contrastText: FINANCIALS.contrastText },
      ...NON_MODULE_COLORS,
    },
    
  })
);

const getFromStorage = (key: string): string | null | undefined => {
  if (typeof window !== "undefined") {
    return window.localStorage.getItem(key);
  }
};
const setToStorage = (key: string, value: string) => {
  if (typeof window !== "undefined") {
    return window.localStorage.setItem(key, value);
  }
};

interface ThemeContextVariables {
  dark: boolean;
}

interface ThemeContextInterface extends ThemeContextVariables {
  setDark: (dark: boolean) => void;
  dark: boolean;
  theme: Theme;
  cultivation: string;
  processing: string;
  finance: string;
}

const ThemeContext = createContext<ThemeContextInterface>({
  dark: false,
  setDark: () => {},
  theme: lightModeCultivation,
  cultivation: CULTIVATION.dark,
  processing: PROCESSING.dark,
  finance: FINANCIALS.dark,
});

export const ThemeContextProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [ctx, setCtx] = useState<ThemeContextVariables>({
    dark: getFromStorage("dark") === "true",
  });
  const router = useRouter();
  const color = router.pathname.startsWith(ProcessingRoute)
    ? "processing"
    : router.pathname.startsWith(FinanceRoute)
    ? "finance"
    : "cultivation";
  const theme =
    color === "processing"
      ? ctx.dark
        ? darkModeProcessing
        : lightModeProcessing
      : color === "finance"
      ? ctx.dark
        ? darkModeFinance
        : lightModeFinance
      : ctx.dark
      ? darkModeCultivation
      : lightModeCultivation;

  const cultivation = darkModeCultivation.palette.primary.main;
  const processing = darkModeProcessing.palette.primary.main;
  const finance = darkModeFinance.palette.primary.main;
  return (
    <ThemeContext.Provider
      value={{
        ...ctx,
        theme,
        cultivation,
        processing,
        finance,
        setDark: (dark: boolean) => {
          setToStorage("dark", dark ? "true" : "false");
          // Bandaid for weird nextjs rendering bug -
          // ThemeProvider only updates light/dark theme changes once
          // Any subsequent calls to setCtx here will cause e.g.
          // dark/light icon to toggle, *BUT THE THEME DOESNT CHANGsE*
          // and it seems like a hard refresh is the fastest fix for now
          if (window) window.location.href = window.location.href;
          else setCtx({ ...ctx, dark });
        },
      }}
    >
      <ThemeProvider theme={theme}>{ctx && children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export const useThemeContext = () => useContext(ThemeContext);

export const useScreenSMDown = () =>
  useMediaQuery(useTheme().breakpoints.down("sm"));
export const useScreenMDDown = () =>
  useMediaQuery(useTheme().breakpoints.down("md"));
export const useScreenLgDown = () =>
  useMediaQuery(useTheme().breakpoints.down("lg"));
